import React from 'react';
import { Redirect } from 'react-router-dom';

import { getPlayerProfileDataForSsr } from '@app/store/actions/teamsActions';
import styled from 'styled-components';
import AppRoutes from '@app/constants/routes';

import { getPersonSchema } from '@app/helpers/structuredMarkupHelpers';
import PlayerInfo from '@app/pages/PlayerProfile/Profiles/PlayerInfo';
import {
  usePlayersDownload,
  useSecondLevelMenuItems,
  useSubMenuItem,
  useTeamListUrl,
} from '@app/pages/Teams/TeamsHooks';
import { Loader, MetaJsonLD, SecondLevelMenu } from '@app/components';
import { PageValidationWrapper } from '@app/pages/components';
import { useBreadcrumbsJsonLD } from '@app/components/Hooks';
import {
  usePlayer,
  usePlayersMultiLangUrl,
  usePlayersStatisticsDownload,
  useRouterParamsValidation,
} from './PlayersHooks';
import PlayerTabs from './Profiles/PlayerTabs';

const PlayerProfile = (): React.ReactElement => {
  const secondLevelMenuItems = useSecondLevelMenuItems();
  const player = usePlayer();
  usePlayersDownload();
  usePlayersStatisticsDownload();
  usePlayersMultiLangUrl();

  const validationError = useRouterParamsValidation();

  const personJsonLD = player ? getPersonSchema({
    personPageUrl: window?.REQUEST_URL || window?.location?.href,
    imageUrl: player.photoLarge.url,
    name: player.firstName,
    surname: player.lastName,
  }) : null;

  const allPlayersMenuItem = useSubMenuItem();

  const breadcrumbsJsonLD = useBreadcrumbsJsonLD(
    player?.seo?.title ?? '',
    {
      pageTitle: allPlayersMenuItem?.seo?.title ?? '',
      linkParams: {
        pathKey: AppRoutes.Teams.path,
        pathParams: {
          teamType: allPlayersMenuItem?.url ?? '',
          categoryName: allPlayersMenuItem?.categories?.[0]?.url ?? '',
        },
      },
    },
  );

  const teamListUrl = useTeamListUrl();

  if (!validationError && (!player || !teamListUrl)) {
    return (<Loader />);
  }
  if (player?.hide && teamListUrl) {
    return (<Redirect to={teamListUrl} />);
  }

  return (
    <PageValidationWrapper error={validationError}>
      { personJsonLD && <MetaJsonLD json={personJsonLD} /> }
      { !!player?.seo && <MetaJsonLD json={breadcrumbsJsonLD} /> }
      <SecondLevelMenu items={secondLevelMenuItems} />
      <Container>
        <InnerContainer>
          <PlayerInfo />
          <PlayerTabs />
        </InnerContainer>
      </Container>
    </PageValidationWrapper>
  );
};

PlayerProfile.serverFetch = [getPlayerProfileDataForSsr];

const Container = styled.div`
  background-color: ${(props): string => props.theme.color.whiteSmoke};
  height: 100%;
`;

const InnerContainer = styled.div`
  max-width: 1280px;
  margin: auto;
`;

export default PlayerProfile;
