import * as Translations from '@app/locales';
import {
  BaseMenuItem,
  Competition,
  Competitions,
  CompetitionTypes,
  Configuration,
  CookieBannerItem,
  ErrorPagesItem,
  ExternalLinkMenuItem,
  Footer,
  GameMenu,
  GeoTargeting,
  GeoTargetingAppearingTypes,
  Header,
  Image,
  Menu,
  MenuItemTypes,
  Partners,
  RelatedItemsType,
  RelatedMenuItem,
  Seo,
  ShareIconsItem,
  ShareIconsType,
  Social,
  SubMenuId,
  SubMenuItem,
  TeamSubMenuId,
  VanityUrl,
} from '@app/types/configurationTypes';
import { NextMatches } from '@app/types/matchTypes';
import { AssetElementValue, ItemWithGeoTargeting, ItemWithSeo } from '@app/services/kentico/types/elementTypes';
import { TeamSquadTypes } from '@app/services/kentico/types/teamsTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { SeasonCategoryIdToConfigCategoryIdMap, SeasonNavigationTypeMap } from '@app/constants/seasonConstants';
import { NewsNavigationTypeMap } from '@app/constants/newsConstants';
import { isOptaSDCompetition } from '@app/services/opta/helpers/competitionHelpers';
import {
  convertImage,
  convertWebCtaButton,
  convertWebDepartment,
  convertWebGoogleAds,
} from '@app/services/kentico/converters/webTemplateConverter';
import { TeamSubMenuTypeMap } from '@app/constants/teamsConstants';
import { AppLanguagesMap, KenticoLangToLangCodeMap } from '@app/constants/localizationConstants';
import {
  WebPartner,
  WebPartnerAdditionalSectionsType,
  WebPartnerBottom,
  WebTemplateData,
  WebTemplateTypes,
} from '@app/types/webTemplateTypes';
import { KenticoWebGoogleAds, KenticoWebPartnerBottomData } from '@app/services/kentico/types/webTemplateTypes';
import { KenticoVanityUrl } from '@app/services/kentico/types/vanityUrlTypes';
import { UserCountryType } from '@app/types/userTypes';
import { checkGetTargetingData } from '@app/helpers/menuHelpers';
import { KenticoItem } from '@app/services/kentico/types/responseTypes';

export const link = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

export function mapSeoData(data: ItemWithSeo, language: LanguageType, hasDefaultMetadata = false): Seo {
  const meta = (data?.seo_metadata__meta_robots?.value ?? [])
    .some(({ codename }) => codename === 'noindex');
  const defaultTitle = language === AppLanguagesMap.it ? 'AC Milan - Sito ufficiale' : 'AC Milan – Official Website';

  return {
    seo: {
      title: data?.seo_metadata__meta_title?.value || (hasDefaultMetadata ? (data?.title?.value ?? '') : defaultTitle),
      description: data?.seo_metadata__meta_description?.value || (hasDefaultMetadata ? (data?.subtitle?.value ?? '') : ''),
      canonicalTag: data?.seo_metadata__canonical_tag?.value ?? '',
      meta,
      socialTitle: data?.social_markup__social_title?.value ?? '',
      socialDescription: data?.social_markup__social_description?.value ?? '',
      socialImageUrl: data?.social_markup__social_image?.value?.[0]?.url ?? '',
    },
  };
}

export function mapGeoTargetingData(data: ItemWithGeoTargeting): GeoTargeting {
  return {
    geo: {
      active: data?.geo_targeting__activate_geo_targeting?.value?.[0]?.codename === 'enable',
      appearing: data?.geo_targeting__menu_item_appearing?.value?.[0]?.codename as GeoTargetingAppearingTypes,
      countries: data?.geo_targeting__countries?.value?.map(({ codename }) => codename.toLowerCase()) ?? [],
    },
  };
}

export function mapImageData(image: AssetElementValue): Image {
  return {
    url: image?.value[0]?.url ?? '',
    description: image?.value[0]?.description ?? '',
  };
}

const MenuIdToCategoryCodeNameMap = {
  [NewsNavigationTypeMap.articles]: 'news',
  [NewsNavigationTypeMap.videos]: 'videos',
  [NewsNavigationTypeMap.pictures]: 'photo_gallery',
};

export function mapBaseMenuItem(data, menuId, parentMenuId): BaseMenuItem {
  const navigationItemData = data?.modular_content[menuId]?.elements ?? null;
  const categoryCodeNameKey = MenuIdToCategoryCodeNameMap[parentMenuId] ?? parentMenuId;
  const type = data?.modular_content[menuId]?.system?.type;
  const googleAdsItem: KenticoItem<KenticoWebGoogleAds> = data?.modular_content?.[`${navigationItemData?.banner?.value?.[0]}`];

  return {
    id: menuId,
    name: navigationItemData?.web_name?.value ?? '',
    url: navigationItemData?.web_url?.value ?? '',
    external: {
      name: navigationItemData?.link_name?.value ?? '',
      url: {
        href: navigationItemData?.link_url?.value ?? '',
        ...link,
      },
    },
    related: {
      id: menuId,
      name: navigationItemData?.title?.value ?? '',
      topLevel: navigationItemData?.top_level?.value[0]
        && mapBaseMenuItem(data, navigationItemData?.top_level?.value[0], null),
      secondLevel: navigationItemData?.second_level?.value[0]
        && mapBaseMenuItem(data, navigationItemData?.second_level?.value[0], navigationItemData?.top_level?.value[0]),
      thirdLevel: navigationItemData?.third_level?.value[0]
        && mapBaseMenuItem(data, navigationItemData?.third_level?.value[0], navigationItemData?.second_level?.value[0]),
      type: MenuItemTypes.RelatedMenuItem,
    },
    value: navigationItemData?.[categoryCodeNameKey]?.value?.[0]?.codename ?? '',
    layout: navigationItemData?.web_layout?.value?.[0]?.name ?? '',
    isVisible: (navigationItemData?.is_visible?.value?.[0]?.name ?? '').toLowerCase() === 'true'
      || type === MenuItemTypes.RelatedMenuItem,
    isPartnersInvisible: !!navigationItemData?.partners_bottom_section?.value?.[0],
    optaId: navigationItemData?.squad_opta_id?.value ?? '',
    type,
    banner: googleAdsItem ? convertWebGoogleAds(googleAdsItem) : null,
    ...mapSeoData(navigationItemData, KenticoLangToLangCodeMap[data?.item?.system?.language]),
    ...mapGeoTargetingData(navigationItemData),
  };
}

export function mapExternalLink(data, menuId): ExternalLinkMenuItem {
  const externalLinkItemData = data?.modular_content[menuId]?.elements ?? null;
  return {
    id: menuId,
    name: externalLinkItemData?.link_name?.value ?? '',
    url: {
      href: externalLinkItemData?.link_url?.value ?? '',
      ...link,
    },
  };
}

export function mapRelatedItem(data, menuId): RelatedMenuItem {
  const relatedItemData = data?.modular_content[menuId]?.elements ?? null;
  return {
    id: menuId,
    name: relatedItemData?.title?.value ?? '',
    topLevel: mapBaseMenuItem(data, relatedItemData?.top_level?.value[0], null),
    secondLevel: relatedItemData?.second_level?.value[0]
       && mapBaseMenuItem(data, relatedItemData?.second_level?.value[0], relatedItemData?.top_level?.value[0]),
    thirdLevel: relatedItemData?.third_level?.value[0]
      && mapBaseMenuItem(data, relatedItemData?.third_level?.value[0], relatedItemData?.second_level?.value[0]),
    type: MenuItemTypes.RelatedMenuItem,
  };
}

export function mapGameMenuItem(data, menuId): GameMenu {
  return {
    id: data?.modular_content[menuId]?.elements?.url?.value ?? '',
    name: data?.modular_content[menuId]?.elements?.url?.value ?? '',
  };
}

function mapSubMenuItemCategories(data, menuId): { categories: BaseMenuItem[] } {
  // exception in CMS
  const id = menuId === 'gallery' ? 'photo_gallery' : menuId;
  return {
    categories: (data?.modular_content[menuId]?.elements?.subitems?.value ?? [])
      .map((filterId) => mapBaseMenuItem(data, filterId, id)),
  };
}

function mapRelatedItems(data, menuId): { relatedItems: RelatedItemsType[] } {
  return {
    relatedItems: (data?.modular_content[menuId]?.elements?.related_items?.value ?? [])
      .map((subMenuId) => {
        const type = (data?.modular_content[subMenuId]?.system.type ?? null);
        return type === MenuItemTypes.ExternalLink ? {
          type: MenuItemTypes.ExternalLink,
          item: mapExternalLink(data, subMenuId),
        } : {
          type: MenuItemTypes.RelatedMenuItem,
          item: mapRelatedItem(data, subMenuId),
        };
      }),
  };
}

function mapSubMenuItem(data, menuId, country: UserCountryType): { navigation: { [key in SubMenuId]: SubMenuItem } } {
  return {
    navigation: (data?.modular_content[menuId]?.elements?.subitems?.value ?? [])
      .reduce((accumulator, subMenuId) => {
        const baseMenuItem = mapBaseMenuItem(data, subMenuId, menuId);
        if (checkGetTargetingData(baseMenuItem.geo, country)) {
          accumulator[subMenuId] = {
            ...baseMenuItem,
            ...mapSubMenuItemCategories(data, subMenuId),
          };
        }

        return accumulator;
      }, {}),
  };
}

export function mapMenuItems(data, country: UserCountryType): Menu {
  return (data?.item?.elements?.sections?.value ?? [])
    .reduce((accumulator, menuId) => {
      const baseMenuItem = mapBaseMenuItem(data, menuId, null);
      if (checkGetTargetingData(baseMenuItem.geo, country)) {
        accumulator[menuId] = {
          ...baseMenuItem,
          ...mapSubMenuItem(data, menuId, country),
          ...mapRelatedItems(data, menuId),
        };
      }
      return accumulator;
    }, {});
}

export const getMenSquadActiveCompetition = (competitions: Competitions): Competition[] => Object.values(competitions)
  .filter(({ categoryId, seasonIds }) => (
    categoryId === TeamSquadTypes.MenSquad && seasonIds.find(({ isActive }) => isActive)
  ));

export const getSquadActiveCompetition = (
  competitions: Competitions, teamSquadType: string,
): Competition[] => Object.values(competitions)
  .filter(({ categoryId, seasonIds }) => (
    categoryId === teamSquadType && seasonIds.find(({ isActive }) => isActive)
  ));

export function mapCompetitions(data, menu: Menu, language: LanguageType): Competitions {
  const categories: Competitions = (menu?.web_season?.navigation?.[SeasonNavigationTypeMap.schedule]?.categories ?? [])
    .filter(({ isVisible }) => isVisible)
    .reduce((accumulator, { id }) => {
      const categoryCompetitions = data?.item?.elements[SeasonCategoryIdToConfigCategoryIdMap[id]]?.value ?? [];
      categoryCompetitions
        .forEach((competitionId) => {
          const elements = data?.modular_content[competitionId]?.elements ?? {};
          const archiveSeasonNames = data?.modular_content[`${competitionId}_archive`]
            ?.elements?.season_name?.value?.split('-') ?? [];
          const archive = data?.modular_content[`${competitionId}_archive`]?.elements?.season_opta_id?.value?.split('-')
            .map((seasonId, i) => ({
              seasonId,
              seasonName: archiveSeasonNames[i] ?? seasonId,
              isActive: false,
            }))
            .filter(({ seasonId }) => seasonId !== elements.season_opta_id?.value) ?? [];

          accumulator[competitionId] = {
            id: competitionId,
            url: elements.url?.value || '',
            type: elements?.competition_type?.value[0]?.codename ?? '',
            categoryId: id,
            optaId: isOptaSDCompetition(competitionId)
              ? elements.opta_sd_competition_id?.value
              : elements.competition_opta_id?.value ?? '',
            name: elements.competition_name?.value || '',
            logo: convertImage(elements?.logo),
            qualifiersMode: elements?.qualifiers_mode?.value[0]?.codename?.toLowerCase() === 'true' ?? false,
            seasonIds: [{
              seasonId: isOptaSDCompetition(competitionId)
                ? elements.opta_sd_season_id?.value
                : elements.season_opta_id?.value ?? '',
              seasonName: elements.season_name?.value ?? '',
              isActive: elements?.is_active?.value[0]?.codename?.toLowerCase() === 'true' ?? false,
            }, ...archive],
          };
        });
      return accumulator;
    }, {});

  const menActiveCompetitionSeasonName = Object.values(categories).find(({ categoryId, seasonIds }) => (
    categoryId === TeamSquadTypes.MenSquad && seasonIds.find(({ isActive }) => isActive)
  ))?.seasonIds?.find(({ isActive }) => isActive)?.seasonName ?? Translations[language]?.['season.active'].toUpperCase();


  const allMenCompetitionsCategory: Competitions = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    men_squad_all: {
      id: 'men_squad_all',
      url: Translations[language]?.['season.active.all'].toLowerCase(),
      type: CompetitionTypes.All,
      categoryId: TeamSquadTypes.MenSquad,
      optaId: '',
      name: Translations[language]?.['season.active.all'],
      qualifiersMode: false,
      seasonIds: [{
        isActive: false,
        seasonId: Translations[language]?.['season.active'].toLowerCase(),
        seasonName: menActiveCompetitionSeasonName,
      }],
    },
  };

  return { ...allMenCompetitionsCategory, ...categories };
}

function mapTeamNextMatches(teamType: TeamSquadTypes, response): [] {
  const useManualMode = response?.item?.elements?.[`${teamType}_select_mode`]?.value?.[0]?.codename === 'enable_manual';
  const manualModeGames = (response?.item?.elements?.[`${teamType}_games`]?.value ?? [])
    .map((codeName) => response?.modular_content?.[codeName]?.elements?.game_opta_id?.value);

  return useManualMode ? manualModeGames : null;
}

export const mapNextMatches = (response): NextMatches => ({
  [TeamSquadTypes.MenSquad]: mapTeamNextMatches(TeamSquadTypes.MenSquad, response),
  [TeamSquadTypes.WomenSquad]: mapTeamNextMatches(TeamSquadTypes.WomenSquad, response),
  [TeamSquadTypes.PrimaveraSquad]: mapTeamNextMatches(TeamSquadTypes.PrimaveraSquad, response),
  [TeamSquadTypes.FuturoSquad]: mapTeamNextMatches(TeamSquadTypes.FuturoSquad, response),
});

/* eslint-disable dot-notation */
export function mapTeamTypeToCompetition(type: TeamSubMenuId, competitions: Competitions): Competition | null {
  switch (type) {
    case TeamSubMenuTypeMap.men: return competitions['men_seriea'];
    case TeamSubMenuTypeMap.women: return competitions['women_seriea'];
    case TeamSubMenuTypeMap.primavera: return null;
    case TeamSubMenuTypeMap.futuro: return null;
    default: return null;
  }
}

function mapLegalLinks(data): RelatedItemsType[] {
  return (data?.item?.elements?.legal_links?.value ?? [])
    .map((subMenuId) => {
      const type = (data?.modular_content[subMenuId]?.system.type ?? null);
      return type === MenuItemTypes.ExternalLink ? {
        type: MenuItemTypes.ExternalLink,
        item: mapExternalLink(data, subMenuId),
      } : {
        type: MenuItemTypes.RelatedMenuItem,
        item: mapRelatedItem(data, subMenuId),
      };
    });
}

function mapSocial(data, element, icon): Social[] {
  return (data?.item?.elements[element]?.value ?? [])
    .map((social): Social => {
      const socialItemData = data?.modular_content[social]?.elements;
      const link = {
        target: '_blank',
        rel: 'noopener noreferrer',
      };
      return {
        name: socialItemData?.social_name?.value ?? '',
        url: {
          href: socialItemData?.link_url?.value ?? '',
          ...link,
        },
        icon: mapImageData(socialItemData[icon]),
      };
    });
}

export function mapFooter(data): Footer {
  return {
    copyright: data?.item?.elements?.copyright?.value ?? '',
    legalLinks: mapLegalLinks(data),
    social: mapSocial(data, 'social_footer', 'social_icon_footer'),
  };
}

export function mapHeader(data): Header {
  return {
    social: mapSocial(data, 'social_header', 'social_icon_header'),
  };
}

function mapPartner(data, value, contentFilter: (value: WebTemplateData) => boolean): WebPartnerBottom {
  const element: KenticoWebPartnerBottomData = value?.elements;

  return {
    type: WebTemplateTypes.WebPartnerBottom,
    id: value?.system?.codename ?? '',
    title: element?.title?.value ?? '',
    content: element?.content?.value
      ?.map((value) => convertWebDepartment(data, data?.modular_content[value], contentFilter),)
      ?.filter((value) => !!value.content.length) ?? [],
  };
}


export function mapPartners(data): Partners {
  const partners = data?.modular_content[data?.item?.elements?.partners?.value?.[0] ?? ''];
  return {
    bottom: mapPartner(data, partners, (value: WebPartner) => value.additionalSections
      .includes(WebPartnerAdditionalSectionsType.Bottom)),
    header: mapPartner(data, partners, (value: WebPartner) => value.additionalSections
      .includes(WebPartnerAdditionalSectionsType.Header))
      .content.reduce((acc, department) => acc.concat(department.content), [] as WebTemplateData[])
      .slice(0, 4) as WebPartner[],
  };
}

const mapAddToCalendarUrl = (data): string => data?.item?.elements?.add_to_calendar?.value ?? '';

type FindCategoryBySlug = <T extends BaseMenuItem = BaseMenuItem>(
  categoryMenuItems: T[],
  categoryName: string,
) => T | undefined

export const findCategoryBySlug: FindCategoryBySlug = (categoryMenuItems, categoryName) => (
  categoryMenuItems.find((category) => category?.url === decodeURIComponent(categoryName))
);

type FindCategoryById = <T extends BaseMenuItem = BaseMenuItem>(
  categoryMenuItems: T[],
  id: string,
) => T | undefined

export const findCategoryById: FindCategoryById = (categoryMenuItems, id) => (
  categoryMenuItems.find((category) => category?.id === id)
);

export function mapCookieBanner(data): CookieBannerItem {
  const element = data?.item?.elements;
  const acceptElement = data?.modular_content?.[element?.cookie_banner_accept_cta?.value?.[0] ?? ''];
  const declineElement = data?.modular_content?.[element?.cookie_banner_decline_cta?.value?.[0] ?? ''];

  return {
    text: element?.cookie_banner_text?.value ?? '',
    cta: {
      position: element?.cookie_banner_cta_position?.value?.[0]?.codename ?? '',
      accept: convertWebCtaButton(data, acceptElement),
      decline: convertWebCtaButton(data, declineElement),
    },
  };
}

export function mapErrorPagesData(data): ErrorPagesItem {
  const ctaButtons = data?.item?.elements?.error_sorry_button?.value;
  return {
    titleSorry: data?.item?.elements?.generic_error_page_text?.value ?? '',
    titleNotFound: data?.item?.elements?.n404_error_page_text?.value ?? '',
    cta: ctaButtons?.map((value) => convertWebCtaButton(data, data?.modular_content?.[value ?? ''])),
  };
}

export function mapShareIcons(data): ShareIconsItem[] {
  const socialSharing = data?.item?.elements?.social_sharing?.value;
  return socialSharing.map((socialIconType) => ({
    type: socialIconType,
    icon: mapImageData(data?.modular_content?.[socialIconType]?.elements.social_icon),
  })).filter((socialIcon) => Object.values(ShareIconsType).includes(socialIcon.type));
}

export function mapVanityUrl(vanityUrl: KenticoVanityUrl, data): VanityUrl {
  return {
    id: vanityUrl.id?.value,
    url: vanityUrl.url?.value,
    relatedItem: mapRelatedItem(data, vanityUrl.related_item?.value?.[0]),
  };
}

export function mapVanityUrls(data): VanityUrl[] {
  const vanityUrls = data?.item?.elements?.vanity_urls?.value;
  return vanityUrls.map((vanityUrl) => mapVanityUrl(data?.modular_content?.[vanityUrl]?.elements, data));
}

export function mapConfig(data, language: LanguageType, country: UserCountryType): Configuration {
  const menu = mapMenuItems(data, country);

  return {
    menu,
    competitions: mapCompetitions(data, menu, language),
    nextMatches: mapNextMatches(data),
    header: mapHeader(data),
    footer: mapFooter(data),
    cookie: mapCookieBanner(data),
    // Todo: specify configuration response type
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    home: mapSeoData(data?.item?.elements, language),
    errorPages: mapErrorPagesData(data),
    shareIcons: mapShareIcons(data),
    partners: mapPartners(data),
    syncCalendarUrl: mapAddToCalendarUrl(data),
    vanityUrls: mapVanityUrls(data),
  };
}
