import { zonedTimeToUtc } from 'date-fns-tz';

import { convertTeamId } from '@app/services/opta/converters/teamsConverter';
import { getMenTeamLogoUrl, getWomenTeamLogoUrl } from '@app/helpers/sourceHelpers';
import { isTeamMilan } from '@app/helpers/teamHelpers';
import {
  MatchPeriodsStatusMap, MatchStatusMap, MatchHalfPeriodsMap, MatchHalfTimePeriodsMap,
} from '@app/constants/matchConstants';

import { AppState } from '@app/store/reducers';
import { WebGoogleAds } from '@app/types/webTemplateTypes';
import { LanguageType } from '@app/types/localizationTypes';
import {
  MatchResult,
  LiveMatchResult,
  MatchStatus,
  LiveMatchResults,
  Game,
  MatchStatistics,
  MatchLineups,
  MatchLineupTeam,
  MatchTeamPlayer, MatchTeamGoal,
} from '@app/types/matchTypes';
import { LiveMatchResultResponse } from '@app/services/opta/types/responseTypes';

const formatMatchPeriod = (period = ''): string => (
  period
    .toLowerCase()
    .replace(/ /g, '')
    .replace(/-/g, '') ?? ''
);

const getMatchGameId = (uID: string, liveResults: LiveMatchResults): string => (
  Object.values(liveResults ?? {})
    .find(({ gameId }) => uID === gameId)?.gameId ?? ''
);

const getMatchStatus = (period: string): MatchStatus => (
  MatchPeriodsStatusMap[formatMatchPeriod(period)] ?? MatchStatusMap.finished
);

const getSDMatchStatus = (period: string): MatchStatus => (
  formatMatchPeriod(period) === 'played' ? MatchStatusMap.finished : MatchStatusMap.upcoming
);

export const getGameByGameOptaId = (state: AppState, language: LanguageType, gameOptaId: string): Game | undefined => (
  state.matchCenter.games?.[language]?.find((game) => gameOptaId === game.gameOptaId)
);

export const getGameByUrlSlug = (state: AppState, language: LanguageType, urlSlug: string): Game | undefined => (
  state.matchCenter.games?.[language]?.find((game) => urlSlug === game.urlSlug)
);

export const getGameGoogleAdsByUrlSlug = (
  state: AppState, language: LanguageType, urlSlug: string,
): WebGoogleAds | undefined => (
  state.matchCenter.games?.[language]?.find((game) => urlSlug === game.urlSlug)?.googleAds
);

export const getMatchTime = (period: string, timeStamp: string): string => {
  const currentTime = new Date().getTime();
  const matchStartTime = zonedTimeToUtc(timeStamp, 'Europe/London').getTime();
  const millisecondsIn = {
    day: 86400000,
    hour: 3600000,
    minute: 60000,
  };
  const minutes = Math.ceil(
    (((currentTime - matchStartTime) % millisecondsIn.day) % millisecondsIn.hour) / millisecondsIn.minute,
  ) ?? 1;

  switch (formatMatchPeriod(period)) {
    case MatchHalfPeriodsMap.firsthalf:
      return minutes > 45 ? `45 + ${minutes - 45}'` : `${minutes}'`;
    case MatchHalfPeriodsMap.secondhalf:
      return minutes > 45 ? `90 + ${minutes - 45}'` : `${minutes + 45}'`;
    case MatchHalfPeriodsMap.firstextrahalf:
    case MatchHalfPeriodsMap.extrafirsthalf:
      return minutes + 90 > 105 ? `105 + ${minutes - 15}'` : `${minutes + 90}'`;
    case MatchHalfPeriodsMap.secondextrahalf:
    case MatchHalfPeriodsMap.extrasecondhalf:
      return minutes + 105 > 120 ? `120 + ${minutes - 15}'` : `${minutes + 105}'`;
    default: return '';
  }
};

export const getLineupFormation = (formation: string): number[] => {
  switch (formation) {
    case '442': return [1, 2, 5, 6, 3, 7, 4, 8, 11, 10, 9];
    case '41212': return [1, 2, 5, 6, 3, 4, 7, 11, 8, 10, 9];
    case '433': return [1, 2, 5, 6, 3, 7, 4, 8, 10, 9, 11];
    case '451': return [1, 2, 5, 6, 3, 7, 4, 10, 8, 11, 9];
    case '4411': return [1, 2, 5, 6, 3, 7, 4, 8, 11, 10, 9];
    case '4141': return [1, 2, 5, 6, 3, 4, 7, 8, 10, 11, 9];
    case '4231': return [1, 2, 5, 6, 3, 8, 4, 7, 10, 11, 9];
    case '4321': return [1, 2, 5, 6, 3, 8, 4, 7, 10, 11, 9];
    case '532': return [1, 2, 6, 5, 4, 3, 7, 8, 11, 10, 9];
    case '541': return [1, 2, 6, 5, 4, 3, 7, 8, 10, 11, 9];
    case '352': return [1, 6, 5, 4, 2, 7, 11, 8, 3, 10, 9];
    case '343': return [1, 6, 5, 4, 2, 7, 8, 3, 10, 9, 11];
    case '31312': return [1, 6, 5, 4, 7, 2, 8, 3, 9, 10, 11];
    case '4222': return [1, 2, 5, 6, 3, 4, 8, 7, 11, 10, 9];
    case '3511': return [1, 6, 5, 4, 2, 7, 11, 8, 3, 10, 9];
    case '3421': return [1, 6, 5, 4, 2, 7, 8, 3, 10, 11, 9];
    case '3412': return [1, 6, 5, 4, 2, 7, 8, 3, 9, 10, 11];
    case '3142': return [1, 5, 4, 6, 8, 2, 7, 11, 3, 9, 10];
    case '4132': return [1, 2, 5, 6, 3, 4, 7, 8, 11, 9, 10];
    case '4240': return [1, 2, 5, 6, 3, 4, 8, 7, 9, 10, 11];
    case '4312': return [1, 2, 5, 6, 3, 7, 4, 11, 8, 9, 10];
    case '343d': case '31213': return [1, 6, 5, 4, 8, 2, 3, 7, 10, 9, 11];
    case '3331': return [1, 6, 5, 4, 2, 8, 3, 10, 7, 11, 9];
    case '3241': return [1, 6, 5, 4, 2, 3, 10, 7, 8, 11, 9];
    default: return [1, 2, 5, 6, 3, 7, 4, 8, 11, 10, 9];
  }
};

export const getPlayerNumberByFormation = (players = [], formation): number => players
  .find(({ place }) => Number(place) === Number(formation))?.['number'] ?? 0;

export const mapMatchResult = ({
  result, liveResults, teams, teamNames, seasonId, optaId,
}): MatchResult => {
  const firstTeamId = convertTeamId(result?.TeamData[0]?.['@attributes']?.TeamRef ?? '');
  const secondTeamId = convertTeamId(result?.TeamData[1]?.['@attributes']?.TeamRef ?? '');
  const gameId = getMatchGameId(result?.['@attributes']?.uID?.replace('g', '') ?? '', liveResults);
  const useLiveResult = gameId && liveResults[gameId]; // available only for FINISHED or LIVE matches
  const matchDate = result?.MatchInfo?.Date?.['@value'] ?? result?.MatchInfo?.Date;

  return {
    gameId,
    seasonId,
    competitionOptaId: optaId,
    gameOptaId: result?.['@attributes']?.uID,
    matchDay: result?.MatchInfo['@attributes']?.MatchDay,
    optaMatchTimeTBC: !!result?.MatchInfo?.Date?.['@attributes']?.['TBC']?.length,
    matchDate: `${zonedTimeToUtc(matchDate, 'Europe/London')}`,
    matchStatus: useLiveResult?.matchStatus ?? getMatchStatus(result?.MatchInfo['@attributes']?.Period.toLowerCase()),
    matchTime: useLiveResult?.matchTime ?? '',
    isHalfTime: useLiveResult?.isHalfTime ?? false,
    stadium: (Array.isArray(result?.Stat ?? {}) ? result?.Stat : [result?.Stat ?? {}])
      ?.find((stat) => stat['@attributes']?.Type?.toLowerCase() === 'venue')?.['@value'] ?? '',
    ticketUrl: '',
    teams: {
      first: {
        id: firstTeamId,
        name: teamNames[firstTeamId]?.teamName || teams
          .find((team) => team['@attributes']?.uID === result?.TeamData[0]?.['@attributes']?.TeamRef)?.Name,
        shortName: teamNames[firstTeamId]?.shortTeamName || '',
        goals: useLiveResult?.teams?.first?.goals ?? result?.TeamData[0]?.['@attributes']?.Score,
        logoUrl: getMenTeamLogoUrl(firstTeamId),
      },
      second: {
        id: secondTeamId,
        name: teamNames[secondTeamId]?.teamName || teams
          .find((team) => team['@attributes']?.uID === result?.TeamData[1]?.['@attributes']?.TeamRef)?.Name,
        shortName: teamNames[secondTeamId]?.shortTeamName || '',
        goals: useLiveResult?.teams?.second?.goals ?? result?.TeamData[1]?.['@attributes']?.Score,
        logoUrl: getMenTeamLogoUrl(secondTeamId),
      },
    },
    ticketsPopup: true,
    ticketsMessage: '',
  };
};

export const mapLiveMatchResult = (result, seasonId, optaId): LiveMatchResult => ({
  seasonId,
  competitionOptaId: optaId,
  gameId: result['@attributes']?.['game-id'] ?? '',
  matchTime: getMatchTime(result['@attributes']?.period, result['@attributes']?.timestamp ?? ''),
  matchStatus: getMatchStatus(result['@attributes']?.period ?? ''),
  isHalfTime: formatMatchPeriod(result['@attributes']?.period ?? '') === MatchHalfTimePeriodsMap.halftime,
  teams: {
    first: {
      id: result['home-team']?.['team-id'] ?? '',
      name: result['home-team']?.['team-name'] ?? '',
      shortName: result['home-team']?.['team-code'] ?? '',
      goals: result['home-team']?.score ?? '',
      logoUrl: getMenTeamLogoUrl(result['home-team']?.['team-id'] ?? ''),
    },
    second: {
      id: result['away-team']?.['team-id'] ?? '',
      name: result['away-team']?.['team-name'] ?? '',
      shortName: result['away-team']?.['team-code'] ?? '',
      goals: result['away-team']?.score ?? '',
      logoUrl: getMenTeamLogoUrl(result['away-team']?.['team-id'] ?? ''),
    },
  },
});

interface FormatLiveMatchResultsProps {
  results: LiveMatchResultResponse | LiveMatchResultResponse[];
  seasonId: string;
  optaId: string;
}

export const formatLiveMatchResults = ({
  results, seasonId, optaId,
}: FormatLiveMatchResultsProps): LiveMatchResult[] => {
  let accumulator: LiveMatchResult[] = [];

  if (Array.isArray(results)) {
    results.forEach((result) => {
      if (Array.isArray(result?.result)) {
        accumulator = accumulator.concat(formatLiveMatchResults({
          results: result?.result ?? [], seasonId, optaId,
        }));
      } else {
        accumulator.push(mapLiveMatchResult(result?.result ?? result, seasonId, optaId));
      }
    });
  } else if (Array.isArray(results?.result)) {
    results.result.forEach((result) => {
      if (Array.isArray(result?.result)) {
        accumulator = accumulator.concat(formatLiveMatchResults({
          results: result?.result ?? [], seasonId, optaId,
        }));
      } else {
        accumulator.push(mapLiveMatchResult(result?.result ?? result, seasonId, optaId));
      }
    });
  } else {
    accumulator.push(mapLiveMatchResult(results?.result ?? results, seasonId, optaId));
  }

  return accumulator;
};

export const mapLiveMatchResults = ({ liveData, seasonId, optaId }): LiveMatchResults => {
  const results = liveData?.feed?.['content.item']?.['content.body']?.results ?? {};
  const formattedResults = formatLiveMatchResults({ results, seasonId, optaId });
  return formattedResults.reduce((accumulator, result) => {
    accumulator[result.gameId] = result;
    return accumulator;
  }, {});
};

export const mapMatchResults = (
  [liveData, staticData, teamNames], { seasonId, optaId, useFilter },
): MatchResult[] => {
  const teams = staticData?.SoccerFeed?.SoccerDocument?.Team || [];
  const MatchData = staticData?.SoccerFeed?.SoccerDocument?.MatchData;
  const results = MatchData?.TeamData ? [MatchData] : MatchData ?? [];
  const liveResults = mapLiveMatchResults({ liveData, seasonId, optaId });

  return results
    .filter((result) => (useFilter ? (isTeamMilan(result?.TeamData[0]?.['@attributes']?.TeamRef)
      || isTeamMilan(result?.TeamData[1]?.['@attributes']?.TeamRef)) : true))
    .map((result) => mapMatchResult({
      result, liveResults, teams, teamNames, seasonId, optaId,
    }));
};

export const mapSDMatchResults = ([results, teamNames], { seasonId, optaId }): MatchResult[] => (
  (results?.match ?? [])
    .map((result): MatchResult => {
      const firstTeamId = result?.matchInfo?.contestant?.[0]?.id ?? '';
      const secondTeamId = result?.matchInfo?.contestant?.[1]?.id ?? '';

      return {
        gameId: '',
        seasonId,
        gameOptaId: result?.matchInfo?.id,
        competitionOptaId: optaId,
        matchDay: result?.matchInfo?.week ?? '',
        matchDate: `${zonedTimeToUtc(
          `${(result?.matchInfo?.date ?? '').replace('Z', '')} ${(result?.matchInfo?.time ?? '').replace('Z', '')}`,
          'UTC/GMT+0',
        )}`,
        optaMatchTimeTBC: (result?.matchInfo?.time || '')?.length === 0,
        matchStatus: getSDMatchStatus(result?.liveData?.matchDetails?.matchStatus ?? ''),
        matchTime: '',
        isHalfTime: false,
        ticketUrl: '',
        stadium: result?.matchInfo?.venue?.longName ?? '',
        teams: {
          first: {
            id: firstTeamId,
            name: teamNames[firstTeamId]?.teamName ?? result?.matchInfo?.contestant?.[0]?.shortName ?? '',
            shortName: teamNames[firstTeamId]?.shortTeamName ?? result?.matchInfo?.contestant?.[0]?.name ?? '',
            goals: result?.liveData?.matchDetails?.scores?.total[result?.matchInfo?.contestant?.[0]?.position ?? ''] ?? '',
            logoUrl: getWomenTeamLogoUrl(firstTeamId),
          },
          second: {
            id: secondTeamId,
            name: teamNames[secondTeamId]?.teamName ?? result?.matchInfo?.contestant?.[1]?.shortName ?? '',
            shortName: teamNames[secondTeamId]?.shortTeamName ?? result?.matchInfo?.contestant?.[1]?.name ?? '',
            goals: result?.liveData?.matchDetails?.scores?.total[result?.matchInfo?.contestant?.[1]?.position ?? ''] ?? '',
            logoUrl: getWomenTeamLogoUrl(secondTeamId),
          },
        },
        ticketsPopup: true,
        ticketsMessage: '',
      };
    })
    .sort((a, b) => new Date(a.matchDate).getTime() - new Date(b.matchDate).getTime())
);

const mapMatchTeamPlayerName = (name): string => (
  name?.Known ? name?.Known : `${(name.First ?? '').substring(0, 1)}. ${name?.Last ?? ''}`);

const mapMatchTeamPlayerPlace = (stats = []): number => ('find' in stats ? Number(stats
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  .find((playerStat) => playerStat?.['@attributes']?.Type === 'formation_place')?.['@value']) ?? 0 : 0);

const mapMatchTeamGoals = (goals = []): MatchTeamGoal[] => ('map' in goals ? goals.map((goal) => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  time: goal?.['@attributes']?.Min && Number(goal?.['@attributes']?.Min) + 1,
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  playerId: goal?.['@attributes']?.PlayerRef ?? '',
})) : []);

const mapMatchTeamPlayerData = (player, personName): MatchTeamPlayer => ({
  firstName: personName?.First,
  lastName: personName?.Last,
  knownName: personName?.Known,
  name: mapMatchTeamPlayerName(personName),
  playerId: player?.['@attributes']?.PlayerRef ?? '',
  position: player?.['@attributes']?.Position?.toLowerCase() ?? '',
  number: player?.['@attributes']?.ShirtNumber ?? '',
  start: (player?.['@attributes']?.Status?.toLowerCase() ?? '') === 'start',
  place: mapMatchTeamPlayerPlace(player?.Stat),
});

export const mapMatchLineupTeamData = (data): MatchLineupTeam => {
  const statsData = Array.isArray(data?.Stat) ? data.Stat : [data.Stat];
  const playersData = Array.isArray(data?.PlayerLineUp?.MatchPlayer) ? data.PlayerLineUp.MatchPlayer : [];
  const formation = statsData
    .find((stat) => stat?.['@attributes']?.Type === 'formation_used')?.['@value'] ?? '442';

  return {
    teamId: convertTeamId(data?.['@attributes']?.TeamRef),
    logoUrl: getMenTeamLogoUrl(convertTeamId(data?.['@attributes']?.TeamRef)),
    players: playersData.map((player) => mapMatchTeamPlayerData(
      player, data.Player.find((p) => p?.['@attributes'].uID === player?.['@attributes']?.PlayerRef)?.PersonName,
    )),
    goals: mapMatchTeamGoals(data?.Goal?.length ? data?.Goal : [data?.Goal]),
    formation: formation === '343d' ? '31213' : formation,
    stats: Object.values(MatchStatistics).reduce((acc, statType) => {
      acc[statType] = Number(statsData
        .find((stat) => stat?.['@attributes']?.Type?.replace(/ /g, '_').toLowerCase() === statType)
        ?.['@value'] ?? 0) ?? 0;
      return acc;
    }, {}),
  };
};

export const mapMatchLineups = (data, gameOptaId = ''): MatchLineups => {
  const lineupsData = data?.SoccerFeed?.SoccerDocument?.length
    ? data?.SoccerFeed?.SoccerDocument
      .find((data) => (data?.['@attributes'].uID ?? '').replace('f', '') === gameOptaId.replace('g', ''))
    : data?.SoccerFeed?.SoccerDocument ?? {};
  const homeTeamData = lineupsData.MatchData?.TeamData
    ?.find((team) => (team?.['@attributes']?.Side ?? '').toLowerCase() === 'home') ?? {};
  homeTeamData.Player = lineupsData.Team
    ?.find((team) => team?.['@attributes']?.uID === homeTeamData?.['@attributes']?.TeamRef)?.Player ?? {};
  const awayTeamData = lineupsData.MatchData?.TeamData
    ?.find((team) => (team?.['@attributes']?.Side ?? '').toLowerCase() === 'away') ?? {};
  awayTeamData.Player = lineupsData.Team
    ?.find((team) => team?.['@attributes']?.uID === awayTeamData?.['@attributes']?.TeamRef)?.Player ?? [];

  return {
    home: mapMatchLineupTeamData(homeTeamData),
    away: mapMatchLineupTeamData(awayTeamData),
  };
};
