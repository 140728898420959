import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import AppRoutes from '@app/constants/routes';
import { Devices } from '@app/styles';
import { Game } from '@app/types/matchTypes';
import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';
import { MatchStatusMap } from '@app/constants/matchConstants';
import { LocalizedDate } from '@app/components/Localization';
import { MatchCenterButton } from '@app/components/Buttons';
import { pushGAEvent } from '@app/helpers/googleHelpers';
import { useLanguage } from '@app/components/Hooks';
import {
  useLineupsSelector,
  useMatchResultsSelector,
  useTeamScoreSelector,
  useMatchTeamsSelector,
} from '@app/pages/MatchCenter/MatchCenterHooks';
import * as Translations from '@app/locales/index';
import { MatchDateFormatMap } from '@app/constants/dateTimeConstants';

interface MatchStatusProps {
  game: Game | undefined;
  isCtaVisible?: boolean;
}

const MatchStatus = ({ game, isCtaVisible = false }: MatchStatusProps): React.ReactElement | null => {
  const result = useMatchResultsSelector();
  const teams = useMatchTeamsSelector();
  const lineups = useLineupsSelector();
  const homeScore = useTeamScoreSelector(result?.teams.first.id ?? '');
  const awayScore = useTeamScoreSelector(result?.teams.second.id ?? '');
  const { formatMessage } = useIntl();
  const language = useLanguage();

  const isMatchLive = result?.matchStatus === MatchStatusMap.live;
  const isMatchFinished = result?.matchStatus === MatchStatusMap.finished;
  const isMatchUpcoming = result?.matchStatus === MatchStatusMap.upcoming;

  const ownGoalLabel = formatMessage({ id: 'matchcenter.ownGoals' });

  return (
    <Container isGalleryAvailable={!!game?.photos.length}>
      {!isCtaVisible && ( // Show heading whenMatch Status is used on Match Center page - not like a widget on any page
        <Heading>
          {teams?.first.name}
          &nbsp;
          {teams?.second.name}
          &nbsp;-&nbsp;
          {game?.competition?.name}
          &nbsp;
          {game?.seasonId}
        </Heading>
      )}
      <Grid>
        <TopArea>
          <Competition>
            {game?.competition?.name}
            &nbsp;-&nbsp;
            <FormattedMessage id="match.day" />
            &nbsp;
            {result?.matchDay}
          </Competition>
          <MatchDate>
            <LocalizedDate date={new Date(result?.matchDate ?? 0)} dateFormat={MatchDateFormatMap[language]} />
          </MatchDate>
        </TopArea>
        <CenterArea>
          <Logo src={result?.teams.first.logoUrl} alt={result?.teams.first.name} />
          <Status>
            {!isMatchUpcoming && (
              <Live isFinished={isMatchFinished}>
                {isMatchLive ? (<FormattedMessage id="match.live" />) : (<FormattedMessage id="match.final" />)}
              </Live>
            )}
            <Goals>
              {isMatchUpcoming ? 'VS' : (
                <>
                  {result?.teams.first.goals}
                  &nbsp;
                  <span>-</span>
                  &nbsp;
                  {result?.teams.second.goals}
                </>
              )}
            </Goals>
            {isMatchLive && (
              <MatchTime>
                { result?.isHalfTime ? <FormattedMessage id="match.halfTime" /> : result?.matchTime }
              </MatchTime>
            )}
            {isMatchFinished && (<Finished><FormattedMessage id="match.fullTime" /></Finished>)}
            {isCtaVisible && (
              <CtaButton
                pathKey={AppRoutes.MatchCenter.path}
                pathParams={{
                  urlSlug: game?.urlSlug ?? '',
                  tab: Translations[language]?.['matchcenter.tabs.lineups'].toLowerCase(),
                }}
                onClick={(): void => pushGAEvent({
                  event: GA_EVENT.CLICK_MATCH_CENTER,
                  category: GA_CATEGORY.MATCHES_WIDGET,
                  action: `${teams?.first.name} - ${teams?.second.name}`,
                  label: `${result?.teams.first.goals ?? 0} - ${result?.teams.second.goals ?? 0}`,
                  language,
                })}
              >
                <FormattedMessage id="match.center" />
              </CtaButton>
            )}
          </Status>
          <Logo src={result?.teams.second.logoUrl} alt={result?.teams.second.name} />
        </CenterArea>
        <BottomArea>
          <section>
            <TeamName>{teams?.first.name}</TeamName>
            {!game?.hidePoints && (<TeamScore>{homeScore}</TeamScore>)}
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore // TODO:REMOVE:ignore
              lineups?.home?.goals?.map(({ time, playerId }) => time && (
                <TeamGoal key={time}>
                  <span>
                    {time}
                    &rsquo;
                  </span>
                  &nbsp;-&nbsp;
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore // TODO:REMOVE:ignore
                    lineups?.home?.players?.find((player) => playerId === player.playerId)?.name
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore // TODO:REMOVE:ignore
                    ?? `${lineups?.away?.players?.find((player) => playerId === player.playerId)?.name} ${ownGoalLabel}`
                  }
                </TeamGoal>
              ))
            }
          </section>
          <section>
            <TeamName>{teams?.second.name}</TeamName>
            {!game?.hidePoints && (<TeamScore>{awayScore}</TeamScore>)}
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore // TODO:REMOVE:ignore
              lineups?.away?.goals?.map(({ time, playerId }) => time && (
                <TeamGoal key={time}>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore // TODO:REMOVE:ignore
                    lineups?.away?.players?.find((player) => playerId === player.playerId)?.name
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore // TODO:REMOVE:ignore
                    ?? `${lineups?.home?.players?.find((player) => playerId === player.playerId)?.name} ${ownGoalLabel}`
                  }
                  &nbsp;-&nbsp;
                  <span>
                    {time}
                    &rsquo;
                  </span>
                </TeamGoal>
              ))
            }
          </section>
        </BottomArea>
      </Grid>
    </Container>
  );
};

export default MatchStatus;

const Container = styled.section<{ isGalleryAvailable: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex: 0 0 450px;
  background-image: url(/images/game_bg_vertical.jpg);
  background-size: cover;
  background-position: center;
  background-color: black;

  @media ${Devices.largeMobile} {
    background-image: url(/images/game_bg_horizontal.jpg);
  }

  @media ${Devices.tabletLarge} {
    background-image: url(${({ isGalleryAvailable }): string => (isGalleryAvailable ? '/images/game_bg_vertical.jpg' : '/images/game_bg_horizontal.jpg')});
    flex: ${({ isGalleryAvailable }): string => (isGalleryAvailable ? '0 0 360px' : '0 0 100%')};
    height: ${({ isGalleryAvailable }): string => (isGalleryAvailable ? 'auto' : '450px')};
  }

  @media ${Devices.desktop} {
    flex: ${({ isGalleryAvailable }): string => (isGalleryAvailable ? '0 0 420px' : '0 0 100%')};
  }
`;

const Heading = styled.h1`
  display: none;
`;

const Grid = styled.div`
  width: 300px;
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TopArea = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 12px;
  line-height: 1.23;
`;

const CenterArea = styled.div`
  display: flex;
  flex: 0 0 80px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const BottomArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  width: 100%;

  & > section {
    display: flex;
    flex-direction: column;
    padding-top: 25px;

    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }
`;

const Status = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const MatchTime = styled.span`
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  color: ${(props): string => props.theme.color.cheninYellow};
  letter-spacing: normal;
`;

const Finished = styled(MatchTime)`
  color: white;
`;

const Competition = styled.span`
  font-weight: 300;
  text-align: center;
`;

const MatchDate = styled(Competition)`
  font-weight: bold;
  margin-bottom: 40px;
`;

const Live = styled.span<{ isFinished: boolean }>`
  display: inline-block;
  font-size: 12px;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  width: 60px;
  line-height: 20px;
  margin: 0 0 5px 0;
  color: white;
  text-transform: uppercase;
  background-color: ${({ isFinished, theme }): string => (isFinished ? 'black' : theme.color.freeSpeechRed)};
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-width: 65px;
  max-height: 65px;
`;

const Goals = styled.span`
  color: white;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 30px;
  font-weight: bold;

  & > span {
    font-size: 16px;
    font-weight: normal;
  }
`;

const TeamName = styled.span`
  color: white;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 18px;
  line-height: 1.36;
  text-transform: uppercase;
`;

const TeamScore = styled(TeamName)`
  color: ${(props): string => props.theme.color.cheninYellow};
  font-size: 10px;
  text-transform: lowercase;
  margin-bottom: 10px;
`;

const TeamGoal = styled.span`
  color: white;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.11px;

  & > span {
    font-weight: bold;
  }
`;

const CtaButton = styled(MatchCenterButton)`
  margin-top: 10px;
  border: 1px solid ${(props): string => props.theme.color.freeSpeechRed};
  background: ${(props): string => props.theme.color.freeSpeechRed};
  color: white;

  &:hover {
    background: transparent;
    color: white;
    border: 1px solid white;
  }
`;
