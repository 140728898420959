import { TeamSubMenuId } from '@app/types/configurationTypes';
import { RoutePath } from '@app/types/routerTypes';

export const TeamsPaths: Record<string, RoutePath> = {
  MEN_SQUAD: 'routes.men_squad',
  WOMEN_SQUAD: 'routes.women_squad',
  PRIMAVERA_SQUAD: 'routes.primavera',
  MILAN_FUTURO_SQUAD: 'routes.futuro_squad',
};

export type TeamTypes = 'men' | 'women' | 'primavera' | 'futuro';

export const TeamSubMenuTypeMap: {
  [key in TeamTypes]: TeamSubMenuId;
} = {
  men: 'squadre_primasquadramaschile',
  women: 'squadre_primasquadrafemminile',
  primavera: 'squadre_primavera',
  futuro: 'squadre_milanfuturo',
};
