import { LayoutConfiguration as LayoutConfigurationType, NewsSubMenuId } from '@app/types/configurationTypes';
import { RoutePath } from '@app/types/routerTypes';
import { HomeBlocks } from '@app/types/homeConfigurationTypes';
import { NewsTypes } from '@app/types/newsTypes';

import { SeasonNavigationTypeMap } from '@app/constants/seasonConstants';
import { TeamSubMenuTypeMap } from '@app/constants/teamsConstants';
import {
  HomeArticles, HomeLanding, HomePhotoGallery, HomeVideos, HomeSlider, HomeGoogleAds,
  HomeEmbeddedOverlay,
} from '@app/pages/Home/componets';


interface MenuIdToPathIdMap {
  [propName: string]: RoutePath;
}

export const NewsNavigationTypeMap: {
  [key in NewsTypes]: NewsSubMenuId;
} = {
  articles: 'news_tutte_news',
  videos: 'news_video',
  pictures: 'news_fotogallery',
};


/* eslint-disable @typescript-eslint/camelcase */
export const menuIdToPathIdMap: MenuIdToPathIdMap = {
  web_news: 'routes.articles',
  [NewsNavigationTypeMap.articles]: 'routes.articles',
  [NewsNavigationTypeMap.videos]: 'routes.videos',
  [NewsNavigationTypeMap.pictures]: 'routes.gallery',

  web_teams: 'routes.men_squad',
  [TeamSubMenuTypeMap.men]: 'routes.men_squad',
  [TeamSubMenuTypeMap.women]: 'routes.women_squad',
  [TeamSubMenuTypeMap.primavera]: 'routes.primavera',
  [TeamSubMenuTypeMap.futuro]: 'routes.futuro_squad',

  web_season: 'routes.season',
  [SeasonNavigationTypeMap.schedule]: 'routes.schedule',
  [SeasonNavigationTypeMap.standings]: 'routes.standings',

  web_ticket_office: 'routes.tickets',
  web_tickets: 'routes.tickets',
  web_template: 'routes.webTemplate',
  web_template_tab: 'routes.webTemplateTab',
};

export const LayoutConfiguration: LayoutConfigurationType = {
  plain_2x2: {
    itemsToDownload: 8,
    isOverlay: false,
  },
  plain_2x3: {
    itemsToDownload: 10,
    isOverlay: false,
  },
  plain_3x3: {
    itemsToDownload: 12,
    isOverlay: false,
  },
  overlay_2x2: {
    itemsToDownload: 8,
    isOverlay: true,
  },
  overlay_2x3: {
    itemsToDownload: 10,
    isOverlay: true,
  },
  overlay_3x3: {
    itemsToDownload: 12,
    isOverlay: true,
  },
};

export const ApplicationConfig = {
  news: {
    defaultNewsLimit: 12,
    latestNewsLimit: {
      articles: 24,
      videos: 24,
      pictures: 24,
    },
  },
  home: {
    latestNewsLimit: {
      articles: 5,
      videos: 5,
      pictures: 4,
    },
  },
};

export const HOME_STANDINGS_TEAMS_NUMBER = 4;

export const HomeBlockIdToComponentMap: {
  [blockId in HomeBlocks]: React.FunctionComponent;
} = {
  [HomeBlocks.GALLERY]: HomePhotoGallery,
  [HomeBlocks.HONOURS]: () => null,
  [HomeBlocks.LATEST_NEWS]: HomeArticles,
  [HomeBlocks.LATEST_VIDEOS]: HomeVideos,
  [HomeBlocks.STORE]: () => null,
  [HomeBlocks.LANDING_1]: HomeLanding,
  [HomeBlocks.LANDING_2]: HomeLanding,
  [HomeBlocks.LANDING_3]: HomeLanding,
  [HomeBlocks.LANDING_4]: HomeLanding,
  [HomeBlocks.LANDING_5]: HomeLanding,
  [HomeBlocks.LANDING_6]: HomeLanding,
  [HomeBlocks.LANDING_7]: HomeLanding,
  [HomeBlocks.LANDING_8]: HomeLanding,
  [HomeBlocks.LANDING_9]: HomeLanding,
  [HomeBlocks.LANDING_10]: HomeLanding,
  [HomeBlocks.SLIDER_1]: HomeSlider,
  [HomeBlocks.SLIDER_2]: HomeSlider,
  [HomeBlocks.SLIDER_3]: HomeSlider,
  [HomeBlocks.SLIDER_4]: HomeSlider,
  [HomeBlocks.GOOGLE_ADS_1]: HomeGoogleAds,
  [HomeBlocks.GOOGLE_ADS_2]: HomeGoogleAds,
  [HomeBlocks.GOOGLE_ADS_3]: HomeGoogleAds,
  [HomeBlocks.GOOGLE_ADS_4]: HomeGoogleAds,
  [HomeBlocks.GOOGLE_ADS_5]: HomeGoogleAds,
  [HomeBlocks.EMBEDDED_OVERLAY]: HomeEmbeddedOverlay,
};

export const HEADER_PARTNERS_COUNT = 3;

export enum WebTemplatePagesSecondLevelMenuId {
  FAQ_MENU = 'fan_faq',
  HALL_OF_FAME = 'club_storia_halloffame',
  HALL_OF_FAME_INDUCTEES = 'hall_of_fame_inductees',
  MEN_FIRST_TEAM_ARCHIVE = 'web_archive_men_first_team',
}
