import { mapGalleryImages } from '@app/helpers/newsHelpers';
import { convertWebGoogleAds } from '@app/services/kentico/converters/webTemplateConverter';

import { Game } from '@app/types/matchTypes';
import { Competitions } from '@app/types/configurationTypes';
import { convertMatchTicketsInfo } from '@app/services/kentico/converters/ticketingConverter';
import { KenticoResponse } from '../types/responseTypes';
import { KenticoGameElement, KenticoGamesModularContent } from '../types/gamesTypes';

const convertToUrlSl = (value = ''): string => value?.toLowerCase().replace(/\s+|[,\\/]/g, '-');

export const convertGames = (
  data: KenticoResponse<KenticoGameElement, KenticoGamesModularContent>, competitions: Competitions,
): Game[] => ((data?.items ?? []).map(({ elements, system }) => {
  const game = {
    gameOptaId: elements.game_opta_id?.value ?? '',
    competition: competitions?.[elements.competition?.value?.[0] ?? '']?.name ?? '',
    optaId: competitions?.[elements.competition?.value?.[0] ?? '']?.optaId ?? '',
    seasonId: elements.season?.value?.[0]?.name ?? '',
    seasonName: competitions?.[elements.competition?.value?.[0] ?? '']?.seasonIds?.find((season) => (
      season?.seasonId === elements.season?.value?.[0]?.name
    ))?.seasonName ?? elements.season?.value?.[0]?.name ?? '',
    urlSlug: elements.url?.value ?? '',
    kenticoMatchTimeTBCLabel: elements.message_text?.value ?? '',
    hidePoints: elements.displayable_points?.value?.[0]?.codename === 'hide',
    photos: mapGalleryImages(data?.modular_content?.[`${elements.photo_gallery?.value?.[0]}`]),
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    googleAds: elements.ads.value?.[0] && convertWebGoogleAds(data.modular_content[elements.ads.value[0]]),
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    ...convertMatchTicketsInfo(elements, data),
  };

  const lastModifiedTime = new Date(system.last_modified).getTime();
  const switchUrlSlugTime = new Date('2022-09-13').getTime();

  const home = convertToUrlSl(game.teams?.home?.name ?? game.teams?.home?.shortName ?? '');
  const away = convertToUrlSl(game.teams?.away?.name ?? game.teams?.away?.shortName ?? '');
  const competition = convertToUrlSl(game?.competition?.name ?? '');
  const season = convertToUrlSl(game?.seasonName);

  const oldIndexedUrlSlug = game.gameOptaId;
  const newSpeakingUrlSlug = (!!home && !!away && !!competition && !!season)
    ? `${season}-${competition}-${home}-${away}-${oldIndexedUrlSlug}` : oldIndexedUrlSlug;

  const urlSlug = lastModifiedTime >= switchUrlSlugTime ? newSpeakingUrlSlug : oldIndexedUrlSlug;

  return { ...game, urlSlug };
}));
